<template>
  <b-card>
    <b-form-file
      v-model="images"
      :state="Boolean(images)"
      placeholder="Choose an image or drop it here..."
      drop-placeholder="Drop image here..."
      multiple
    ></b-form-file>
    <div class="mt-3">Selected image: {{ images ? images.name : '' }}</div>
    <b-progress :value="in_progress" :max="max_progress" show-progress animated></b-progress>
    <b-button block variant="primary" @click="storeImages">Add To Collection</b-button>
  </b-card>
</template>

<script>
import {http_v1} from '@/axios'

export default {
  props: {
    collection_id: {
      Type: String
    }
  },
  data () {
    return {
      api_route: 'api/admin/v1/collection_images',
      images: [],
      in_progress: 0,
      max_progress: 100,
      progressInfos: [],
      message: "",
      fileInfos: [],
    }
  },
  methods: {
    uploadApi(file, onUploadProgress) {
      let formData = new FormData()
      formData.append("image", file)
      formData.append("collection_id", this.collection_id)
      return http_v1.post(this.api_route, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        },
        onUploadProgress
      })
    },
    uploadImage(idx, file) {
      this.progressInfos[idx] = { percentage: 0, fileName: file.name }
      this.uploadApi(file, (event) => {
        this.progressInfos[idx].percentage = Math.round(100 * event.loaded / event.total)
        this.in_progress = this.progressInfos[idx].percentage
      }).then((response) => {
        let prevMessage = this.message ? this.message + "\n" : ""
        this.message = prevMessage + response.data.message
      }).catch(() => {
        this.progressInfos[idx].percentage = 0
        this.message = "Could not upload the file:" + file.name
      })
    },
    storeImages () {
      this.message = ""
      for(let i = 0; i< this.images.length; i++ ) {
        this.uploadImage(i, this.images[i])
      }
      this.images = []
    },
  }
}
</script>

      
