<template>
  <div>
    <vuex-breadcrumb></vuex-breadcrumb>
    <b-row>
      <b-col md="8">
        <collection-update
          :id="id"
          @gotAttrs="assignAttrs"
        ></collection-update>
        <br>
        <collection-image-create :collection_id="id"></collection-image-create>
      </b-col>
      <b-col md="4">
        <collection-image-index
          :filtering="filtering"
        />
      </b-col>
    </b-row>
    <product-item-index
      :filtering="filtering"
      :ams_template="ams_template"
    ></product-item-index>
  </div>
</template>

<script>
import VuexBreadcrumb from './VuexBreadcrumb.vue'
import CollectionUpdate from './CollectionUpdate.vue'
import CollectionImageIndex from './CollectionImageIndex.vue'
import CollectionImageCreate from './CollectionImageCreate.vue'
import ProductItemIndex from './ProductItemIndex.vue'

export default {
  components: {
    VuexBreadcrumb,
    CollectionUpdate,
    CollectionImageIndex,
    CollectionImageCreate,
    ProductItemIndex
  },
  props: {
    id: String
  },
  data () {
    return {
      filtering: {
        collection_ids: this.id
      },
      ams_template: {
        type: 'product-items',
        description: undefined,
        attributes: {
          'product-listing-id': undefined,
          'collection-id': this.id,
          'purchased-price-cents': 0,
          'estimated-min-price-cents': 0,
          'estimated-max-price-cents': 0,
          'authenticated-min-price-cents': 0,
          'authenticated-max-price-cents': 0,
          'asking-price-cents': 0,
          'sold-price-cents': 0,
          'estimated-quality': 9000,
          'authenticated-quality': 0
        }
      },
      attrs: {},
    }
  },
  methods: {
    assignAttrs(attrs, description) {
      this.attrs = Object.assign( {}, attrs)
      this.ams_template.description = description + ', ' + attrs['name']
    }
  }
}
</script>